import { WindowWithDataLayer } from "src/types/gtm"
declare const window: WindowWithDataLayer

interface DataLayerItem {
  [key: string]: unknown
}

export const pushToDataLayer = (data: DataLayerItem) => {
  if (!process.env.GTM_ID) return
  if (typeof window !== "undefined" && Array.isArray(window.dataLayer)) {
    window.dataLayer.push(data)
  } else {
    console.warn("dataLayer is not defined or is not an array")
  }
}
