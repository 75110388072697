// eslint-disable-next-line @typescript-eslint/no-var-requires
const plugin = require("tailwindcss/plugin")

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ["./src/**/*.{js,jsx,ts,tsx}"],
  theme: {
    screens: {
      xs: "320px",
      sm: "425px",
      md: "640px",
      lg: "767px",
      xl: "1024px"
    },
    extend: {
      colors: {
        // Default
        primary: {
          10: "#CDF4C6",
          30: "#99D4A6",
          50: "#005E49",
          70: "#004E3D"
        },
        secondary: {
          10: "#EBE5FC",
          30: "#C9C4DB",
          50: "#73426B"
        },
        "secondary-brand": "#FDEEA0",
        warning: {
          10: "#F8D5D1",
          50: "#9B2719"
        },
        caution: {
          10: "#FFED8D"
        },
        success: {
          10: "#CDF4C6",
          50: "#005E49"
        },
        neutral: {
          10: "#EDE7E2",
          30: "#CAC6C1",
          50: "#545250",
          70: "#1B1B1B"
        },
        surface: "#FFFFFF",
        background: "#FBF5F0",
        call: "#005D49",
        hangup: "#9B2719",
        grey: "#B3B3B3",
        accent: "#FFBF44",
        // On color
        "on-primary": {
          10: "#004E3D",
          30: "#004E3D",
          50: "#FFFFFF",
          70: "#FFF9F3"
        },
        "on-secondary": {
          10: "#5F3658",
          30: "#5F3658",
          50: "#FFFFFF"
        },
        "on-warning": {
          10: "#822015",
          50: "#FFFFFF"
        },
        "on-caution": {
          10: "#545250"
        },
        "on-success": {
          10: "#004E3D",
          50: "#FFFFFF"
        },
        "on-neutral": {
          10: "#464442",
          30: "#464442",
          50: "#FFFFFF"
        },
        "on-surface": "#1B1B1B",
        "on-background": "#1B1B1B",
        "on-call": "#FFFFFF",
        "on-hangup": "#FFFFFF",
        "on-grey": "#FFFFFF",
        "on-accent": "#1B1B1B",
        "hover-primary": "#005E491F",
        "hover-primary-10": "#BDE7BB",
        "hover-secondary-10": "#D8CCF0",
        "hover-secondary": "#005E491F",
        "hover-accent": "#FFBF44",
        "focus-primary-10": "#B4E0B6",
        "focus-secondary-10": "#CFC3E8",
        "active-primary-10": "#9CCCA5",
        "active-secondary": "#005E493D",
        backdrop: "rgb(0 0 0 / 20%)"
      },
      fontFamily: {
        display: ["var(--font-display)"],
        text: ["var(--font-text)"],
        giga: ["var(--font-khgiga)"]
      },
      letterSpacing: {
        display: "-0.03em",
        text: "-0.03em"
      },
      boxShadow: {
        elevation: "0 2px 5px rgb(0 0 0 / 10%)",
        "elevation-1": "0px 4px 12px 0px rgba(0, 0, 0, 0.16)",
        "elevation-2": "0 10px 38px -10px rgb(22 23 24 / 35%), 0 10px 20px -15px rgb(22 23 24 / 20%)",
        inputInner: "0px 0px 0px 1px rgba(95,54,88,1) inset"
      },
      transitionTimingFunction: {
        rotate: "cubic-bezier(0.65, 0, 0.35, 1)"
      },
      keyframes: {
        enterFromRight: {
          from: { opacity: 0, transform: "translateX(200px)" },
          to: { opacity: 1, transform: "translateX(0)" }
        },
        enterFromLeft: {
          from: { opacity: 0, transform: "translateX(-200px)" },
          to: { opacity: 1, transform: "translateX(0)" }
        },
        exitToRight: {
          from: { opacity: 1, transform: "translateX(0)" },
          to: { opacity: 0, transform: "translateX(200px)" }
        },
        exitToLeft: {
          from: { opacity: 1, transform: "translateX(0)" },
          to: { opacity: 0, transform: "translateX(-200px)" }
        },
        scaleIn: {
          from: { opacity: 0, transform: "rotateX(-10deg) scale(0.9)" },
          to: { opacity: 1, transform: "rotateX(0deg) scale(1)" }
        },
        scaleOut: {
          from: { opacity: 1, transform: "rotateX(0deg) scale(1)" },
          to: { opacity: 0, transform: "rotateX(-10deg) scale(0.95)" }
        },
        fadeIn: {
          from: { opacity: 0 },
          to: { opacity: 1 }
        },
        fadeOut: {
          from: { opacity: 1 },
          to: { opacity: 0 }
        }
      },
      animation: {
        scaleIn: "scaleIn 200ms ease",
        scaleOut: "scaleOut 200ms ease",
        fadeIn: "fadeIn 200ms ease",
        fadeOut: "fadeOut 200ms ease",
        enterFromLeft: "enterFromLeft 250ms ease",
        enterFromRight: "enterFromRight 250ms ease",
        exitToLeft: "exitToLeft 250ms ease",
        exitToRight: "exitToRight 250ms ease"
      },
      flex: {
        1: "0 0 8.333333%",
        2: "0 0 16.666667%",
        3: "0 0 25%",
        4: "0 0 33.333333%",
        5: "0 0 41.666667%",
        6: "0 0 50%",
        7: "0 0 58.333333%",
        8: "0 0 66.666667%",
        9: "0 0 75%",
        10: "0 0 83.333333%",
        11: "0 0 91.666667%",
        12: "0 0 100%"
      },
      maxWidth: {
        site: "120rem",
        grid: "80rem",
        footer: "120rem",
        auto: "auto",
        floatingCta: "calc(100% - 72px)",
        1: "8.333333%",
        2: "16.666667%",
        3: "25%",
        4: "33.333333%",
        5: "41.666667%",
        6: "50%",
        7: "58.333333%",
        8: "66.666667%",
        9: "75%",
        10: "83.333333%",
        11: "91.666667%",
        12: "100%"
      },
      width: {
        icon: "calc(100% - 64px)"
      }
    }
  },
  plugins: [
    plugin(({ matchUtilities }) => {
      matchUtilities({
        perspective: value => ({
          perspective: value
        })
      }),
        ["prettier-plugin-tailwindcss"]
    })
  ],
  safelist: [
    "bg-surface",
    "bg-background",
    {
      pattern: /^flex-[0-9]/,
      variants: ["sm", "md", "lg", "xl"]
    },
    {
      pattern: /^max-w-[0-9]/,
      variants: ["sm", "md", "lg", "xl"]
    },
    {
      pattern: /justify-(start|end|center|between|around|evenly|stretch)/,
      variants: ["md"]
    },
    {
      pattern: /items-(start|center|end|baseline|stretch)/,
      variants: ["md"]
    }
  ]
}
