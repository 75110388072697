import { trackEvent } from "@lib/amplitude/amplitude"
import { ClinicConfigStoryblok, ConfigStoryblok } from "@storyblok-types"
import { ISbStoryData } from "@storyblok/react/rsc"

export const mainNavigationListingEvent = (pagePath: string) =>
  trackEvent("main.navigation.listing_cta_pressed", { page_path: pagePath })
export const mainNavigationSeekCareEvent = () => trackEvent("main.navigation.seek_care_cta_pressed")

// ========== Main Footer Events ========== //

export const mainFooterDownloadAppAndroidEvent = (pagePath: string) =>
  trackEvent("main.footer.download_app_android", { page_path: pagePath })
export const mainFooterDownloadAppIosEvent = (pagePath: string) =>
  trackEvent("main.footer.download_app_ios", { page_path: pagePath })

// ========== Digital Care Events ========== //

export const digitalCareGetTheAppSectionDownloadAppIosEvent = (pagePath: string) =>
  trackEvent("digital_care.get_the_app_section.download_app_ios", { page_path: pagePath })

export const digitalCareGetTheAppSectionDownloadAppAndroidEvent = (pagePath: string) =>
  trackEvent("digital_care.get_the_app_section.download_app_android", { page_path: pagePath })

// ========== Clinic Events ========== //

export const clinicPageHomeSeekCareSectionDigitalCareCardLinkEvent = (name: string) =>
  trackEvent("clinic_page_home.seek_care_section.digital_care_card_link_pressed", { clinic_name: name })

export const clinicPageHomeChooseClinicsSectionUSPListingEvent = () =>
  trackEvent("clinic_page_home.choose_clinics_section_usp.listing_cta_pressed")

export const clinicPageNavigationListingEvent = (name: string, pagePath: string) =>
  trackEvent("clinic_page.navigation.listing_cta_pressed", { clinic_name: name, page_path: pagePath })

export const clinicPageHomeSeekCareSectionUrgentCareCardLinkEvent = (name: string) =>
  trackEvent("clinic_page_home.seek_care_section.urgent_care_card_link_pressed", { clinic_name: name })

// ========== Listing Events ========== //

export const listingInfoSectionChooseClinicEvent = () => trackEvent("listing.info_section.choose_clinic_cta_pressed")

export const listingMainScreenShown = () => trackEvent("listing.main.screen_shown")
export const listingMainCtaPressed = () => trackEvent("listing.main.cta_listing_pressed")
export const listingPdfListingScreenShown = () => trackEvent("listing.pdf_listing.screen_shown")
export const listingPatientsSelectionAddChildPressed = () => trackEvent("listing.patients_selection.add_child_pressed")
export const listingPdfListingInitiateSigningPressed = () => trackEvent("listing.pdf_listing.initiate_signing_pressed")
export const listing1177ListingInitiatePressed = () => trackEvent("listing.1177_listing.initiate_signing_pressed")
export const listingPdfSigningScreenShown = () => trackEvent("listing.pdf_signing.screen_shown")
export const listingPdfSigningCtaPressed = () => trackEvent("listing.pdf_signing.cta_pressed")
export const listingPostSigningScreenShown = () => trackEvent("listing.post_sign_listing.success_screen_shown")
export const listingPostSigningAppCtaPressed = () => trackEvent("listing.post_sign_listing.download_app_cta_pressed")

export const findClinicsClinicListListingEvent = (name: string, method: string, pagePath: string) =>
  trackEvent("find_clinics.clinic_list.listing_cta_pressed", { clinic_name: name, method: method, page_path: pagePath })

export const findClinicsClinicListBookTime = (name: string, pagePath: string) =>
  trackEvent("find_clinics.clinic_list.book_time_urgent_care_cta_pressed", { clinic_name: name, page_path: pagePath })

export const downLoadAppBasedOnPlatform = (
  config: ISbStoryData<ConfigStoryblok | ClinicConfigStoryblok>,
  eventCallback: () => void
) => {
  if (typeof window !== "undefined" && typeof navigator !== "undefined") {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream
    const isAndroid = /android/i.test(userAgent)

    if (isIOS) {
      eventCallback()
      window.open(`${config.content?.app_store_link}`)
    }

    if (isAndroid) {
      eventCallback()
      window.open(`${config.content?.google_play_link}`)
    }
  }
}
