import { ListingPageStoryblok } from "@/types/component-types"
import { ISbStoryData } from "storyblok-js-client"
import { create } from "zustand"

export type ListingStoreProps = {
  story: ISbStoryData<ListingPageStoryblok> | undefined
}

export const useListingStore = create<ListingStoreProps>(() => ({
  story: undefined
}))
