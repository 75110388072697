import * as amplitude from "@amplitude/analytics-browser"

import { AMPLITUDE_API_KEY } from "@config/config"
import { pushToDataLayer } from "@lib/gtm/pushToDataLayer"

export const initAmplitude = () => {
  if (AMPLITUDE_API_KEY) {
    amplitude.init(AMPLITUDE_API_KEY, {
      defaultTracking: {
        pageViews: true,
        attribution: true,
        fileDownloads: false,
        formInteractions: false,
        sessions: false
      },
      trackingOptions: {
        platform: true,
        language: true,
        ipAddress: false
      },
      logLevel: amplitude.Types.LogLevel.None,
      serverZone: "EU"
    })
  }
}

export const trackEvent = (name: string | amplitude.Types.BaseEvent, eventProperties?: Record<string, unknown>) => {
  amplitude.track(name, eventProperties)
  pushToDataLayer({ event: name })
}

export const logEvent = (name: string | amplitude.Types.BaseEvent, eventProperties?: Record<string, unknown>) => {
  amplitude.logEvent(name, eventProperties)
}
