import { ClassValue, clsx } from "clsx"
import { addDays, differenceInMilliseconds, isAfter, isBefore, parseISO } from "date-fns"
import { twMerge } from "tailwind-merge"
import tailwindConfig from "../../tailwind.config.js"

type DateItem<T> = T & {
  date?: string
  time_from?: string
  time_to?: string
}

export const slugToString = (slug: undefined | string | string[]): string =>
  (slug && (typeof slug === "string" ? slug : slug.join("/"))) ?? ""
export const takeSlugByIndex = (slug: undefined | string | string[], index: number = 0): string =>
  (slug && typeof slug === "string" ? slug : (slug as string[])[index]) ?? ""

export const filterDatesAfterDate = <T>(dates: DateItem<T>[], afterDate: Date): DateItem<T>[] => {
  return dates.filter(currDate => isAfter(parseISO(currDate.date || ""), afterDate))
}

export const filterDatesNext30Days = <T>(dates: DateItem<T>[], isPreview: boolean = true): DateItem<T>[] => {
  const today = new Date()
  const thirtyDaysFromNow = addDays(today, 30)

  if (isPreview) return dates

  return dates.filter(currDate => {
    const date = parseISO(currDate.date || "")
    return isAfter(date, today) && isBefore(date, thirtyDaysFromNow)
  })
}

export const findClosestDate = <T>(dates: DateItem<T>[]): T | null => {
  let closestDate: T | null = null
  let closestDiff = Infinity
  const today = new Date()

  dates.forEach(currDate => {
    const date = addDays(parseISO(currDate.date || ""), 1) // Todo: Fix this, it's a temporary hack to make the date work
    const diff = Math.abs(differenceInMilliseconds(date, today))
    if (diff < closestDiff && isAfter(date, today)) {
      closestDate = currDate
      closestDiff = diff
    }
  })

  return closestDate
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortObjectsAlphabetically = <T extends { [key: string]: any }>(objects: T[], key: keyof T): T[] => {
  objects.sort((a, b) => {
    const itemA = String(a[key]).toUpperCase()
    const itemB = String(b[key]).toUpperCase()
    if (itemA < itemB) return -1
    if (itemA > itemB) return 1
    return 0
  })

  return objects
}

export function sortByNestedProperty<T>(arr: T[], nestedPropertyPath: string): T[] {
  return arr.slice().sort((a, b) => {
    const aValue = getNestedPropertyValue(a, nestedPropertyPath)
    const bValue = getNestedPropertyValue(b, nestedPropertyPath)

    return String(aValue).localeCompare(String(bValue))
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getNestedPropertyValue(obj: any, path: string): any {
  return path.split(".").reduce((nestedObj, key) => nestedObj[key], obj)
}

/**
 *  This function is used to throw an error if a value is null or undefined.
 *  It is used to prevent null or undefined values from being passed to functions that do not accept them.
 *  It throws an error with the given message or a default error message.
 */
export const nullThrows = <T>(value: T | null | undefined, message?: string): T => {
  if (value == null) {
    throw new Error(message || "Got null value")
  }
  return value
}

/*
	Function to merge classes with tailwind but also leverage the clsx functionality
	to be able to use objects as classes and still get the small build file tailwind provides.
*/
export const cn = (...classes: ClassValue[]) => {
  return twMerge(clsx(classes))
}

export const findColorName = (hexCode: string | undefined) => {
  if (!hexCode) return
  if (!hexCode.includes("#")) return hexCode
  const colorConfig = tailwindConfig?.theme?.extend?.colors
  if (!colorConfig) return undefined
  for (const [colorName, colorValue] of Object.entries(colorConfig)) {
    if (typeof colorValue === "string" && colorValue === hexCode) {
      return colorName
    }
  }

  return undefined
}

/*
 Calculates the distance between two geographic coordinates (latitude and longitude) using the Haversine formula.
*/
export const getHaversineDistance = (
  latitude1: number,
  longitude1: number,
  latitude2: number,
  longitude2: number
): number => {
  const degreesToRadians = (degrees: number): number => {
    return (degrees * Math.PI) / 180
  }

  const EARTH_RADIUS_KM = 6371
  const deltaLatitude = degreesToRadians(latitude2 - latitude1)
  const deltaLongitude = degreesToRadians(longitude2 - longitude1)

  const haversineFormulaComponent =
    Math.sin(deltaLatitude / 2) * Math.sin(deltaLatitude / 2) +
    Math.cos(degreesToRadians(latitude1)) *
      Math.cos(degreesToRadians(latitude2)) *
      Math.sin(deltaLongitude / 2) *
      Math.sin(deltaLongitude / 2)

  const angularDistance = 2 * Math.atan2(Math.sqrt(haversineFormulaComponent), Math.sqrt(1 - haversineFormulaComponent))
  const distance = EARTH_RADIUS_KM * angularDistance

  return distance
}
